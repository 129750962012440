<template>
  <div>
    <table class="table">
      <button @click="getTeams()">Получить команды</button>
      <button @click="addTeams()">Записать команды</button>
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">name</th>
          <th scope="col">transl</th>
          <th scope="col">logo</th>
          <th scope="col">sport_id</th>
          <th scope="col">g</th>
          <th scope="col">country</th>
        </tr>
      </thead>
      <tbody>
        <!-- <tr v-for="item in teams" :key="item.id">
          <th scope="row">{{item.id}}</th>
          <td>{{ item.name }}</td>
          <td>{{ item.name_translations.ru }}</td>
          <td><img style=" height: 30px" :src="item.logo" alt=""></td>
          <td>{{ item.sport_id }}</td>
          <td>{{ item.gender }}</td>
          <td>{{ item.country }}</td>
        </tr> -->
      </tbody>
    </table>

    <div >
      <form v-for="item in teams" :key="item.id" action="">
        <input type="text" :value="item.id" id="" />
        <input type="text" :value="item.name_translations.ru" id="" />
        <input type="text" :value="item.logo" id="" />
        <img style=" height: 30px" :src="item.logo" alt="">
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      teams: [],
      postTeams: {
        name: '',
      },
    };
  },

  methods: {
    getTeams() {
      const options = {
        method: "GET",
        url: "https://sportscore1.p.rapidapi.com/sports/1/teams",
        params: { page: "1" },
        headers: {
          "X-RapidAPI-Host": "sportscore1.p.rapidapi.com",
          "X-RapidAPI-Key":
            "7b21a1fe9emsh6a7065654827f80p144bb2jsn4759dadffdf0",
        },
      };
      axios
        .request(options)
        .then((response) => {
          this.teams = response.data.data;
        })
        .catch(function (error) {
          console.error(error);
        });
    },

    addTeams() {
        axios
            .post('/teams/store', {
                
            });
    },
  },
};
</script>
